let gToolTipEnabled = true;

function getToolTipButtons() {
    return document.querySelectorAll(".tooltiptext");
}

function onScrollEvent() {
    const scroll_pos = document.documentElement.scrollTop || document.body.scrollTop;
    const tool_tip_enabled = scroll_pos < 200;
    if (gToolTipEnabled === tool_tip_enabled) {
        return;
    }
    const tool_tip_buttons = getToolTipButtons();
    const display_type = tool_tip_enabled ? "block" : "none";
    tool_tip_buttons.forEach(button => {
        button.style.display = display_type;  // eslint-disable-line no-param-reassign
    });
    gToolTipEnabled = tool_tip_enabled;
}

export function initToolTip() {
    document.addEventListener("scroll", onScrollEvent);
}