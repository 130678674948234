

/* eslint-disable no-underscore-dangle,no-func-assign */

import { cssGetGlobalVar, cssSetGlobalVar } from './util';

// Relayouts are expensive! Allow a half second for the window view
// to stop resizing before issuing a re-layout. Wihtout this several
// re-layout commands will be scheduled and then start stacking up. The
// browse would freeze and provide a poor user experience. This bug
// appeared once the history was extended to multiple days. The processing
// got super expensive.
const DEFERRED_RESIZE_TIMEOUT = 500;


const ideal_video_container_width = 214;
const ideal_video_thumbnail_height = 120;
const max_videos_per_row = 5;

function __resize() {
    console.log("Not initialized yet.");
}

function gridLayoutGetNumColumns() {
    return Number.parseInt(cssGetGlobalVar('--videos-num-columns'), 10);
}

let __pending_resize_task = null;
let __init_responsive_grid_layout_called = false;
function initResponsiveGridLayout() {
    if (__init_responsive_grid_layout_called) {
        console.log("initResponsiveGridLayout() called more than once");
        return;
    }
    __init_responsive_grid_layout_called = true;

    const dom_video_section = document.getElementById("video_section");
    function get_container_width() {
        try {
            return window.getComputedStyle(dom_video_section, null)
                .getPropertyValue('width');
        } catch (e) {
            console.log('Error happened with', e);
            return dom_video_section.currentStyle.width;
        }
    }

    function clamp(val, min, max) {
        return val > max ? max : val < min ? min : val; /* eslint-disable-line */
    }


    function resize_video_elements() {
        const view_width = parseFloat(get_container_width());
        const padding_width = parseFloat(cssGetGlobalVar("--videos-height-padding"));
        const fvideos_per_row = (view_width) / (ideal_video_container_width + padding_width);
        const videos_per_row = clamp(parseInt(fvideos_per_row, 10), 1, max_videos_per_row);
        const total_padding = padding_width * (videos_per_row - 1);
        const padding_per_video = total_padding / videos_per_row;
        // TODO: Why is it padding_per_video/2 and not just padding_per_video??
        let new_width = ((view_width / videos_per_row) - (padding_per_video));
        const scale = new_width / ideal_video_container_width;
        new_width = parseInt(new_width, 10);
        const new_height = parseInt(scale * ideal_video_thumbnail_height, 10);
        if (Number.isNaN(new_width) || Number.isNaN(new_height)) {
            console.log('NaN values, skipping resize.');
            return false;  // Signal failed attempt to allow a retry again later.
        }
        cssSetGlobalVar('--video-container-width', `${new_width}px`);
        cssSetGlobalVar('--video-thumbnail-height', `${new_height}px`);
        cssSetGlobalVar('--videos-num-columns', `${videos_per_row}`);
        return true;
    }

    function __get_width() {
        return window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
    }
    let __last_width = __get_width();
    let __initialized = false;
    function do_resize() {
        const new_width = __get_width();
        if (!__initialized || new_width !== __last_width) {
            __last_width = new_width;
            // The css global variables load async and until that completes, resize_video_elements()
            // will return false.
            const ok = resize_video_elements();
            __initialized = __initialized || ok;
        }
    }

    function deferred_resize() {
        clearTimeout(__pending_resize_task);
        __pending_resize_task = setTimeout(do_resize, DEFERRED_RESIZE_TIMEOUT);
    }

    __resize = resize_video_elements;

    // setInterval(function() {
    //    console.log('Console width:', __get_width());
    // }, 1000);

    // Fires off whenever the #video_section has changes.
    new MutationObserver(deferred_resize).observe(
        document.getElementById('video_section'),
        { attributes: true, childList: true, subtree: true });

    // do_resize();
    document.addEventListener('onreadystatechange', () => {
        if (document.readyState === 'complete') {
            deferred_resize();
        }
    });
    document.addEventListener('DOMContentLoaded', deferred_resize);
    window.addEventListener('resize', deferred_resize);
    // do a resize soon.
    setTimeout(do_resize, 0);
}

function gridResize() {
    // Forces a resize no matter what.
    __resize();
}

export { initResponsiveGridLayout, gridLayoutGetNumColumns, gridResize };