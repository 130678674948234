import localForage from "localforage";

const db = localForage.createInstance({
    name: "blast.video"
});
db.config({
    driver: [localForage.WEBSQL, localForage.INDEXEDDB, localForage.LOCALSTORAGE], // Force WebSQL; same as using setDriver()
    version: 1.0,
    size: 4980736, // Size of database, in bytes. WebSQL-only for now.
    storeName: 'keyvaluepairs', // Should be alphanumeric, with underscores.
    description: 'some description'
});

export function db_instance() {
    return db;
}