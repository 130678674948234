

import { IS_PROD, ANALYTICS_ENDPOINT } from "./analytics_info";

const ENABLED = false;

function fetchOptions() {
    const options = {};
    if (!IS_PROD) {
        options.mode = "no-cors";
    }
    return options;
}

export function trackLink(link) {
    let url = `${ANALYTICS_ENDPOINT}?event_name=${link}&user_agent=${navigator.userAgent}`;
    if (document.referrer) {
        url += `&ref=${document.referrer}`;
    }
    const options = fetchOptions();
    const uri = encodeURI(url);
    const f = () => {
        if (ENABLED) {
            try {
                fetch(uri, options);
            } catch (e) {
                console.warn(`${ANALYTICS_ENDPOINT} Failed: ${link} with error: ${e}`)
            }
        }
    };
    setTimeout(f);
}

export function analyticsTrackThisPage() {
    trackLink(window.location.href);
}

export function initAnalytics() {
    analyticsTrackThisPage();
}
