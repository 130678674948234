
function safeGetElementById(s) {
    const out = document.getElementById(s);
    if (!out) {
        console.log('Could not find element', s);
    }
    return out;
}


export function domPopupBookmark() {
    return safeGetElementById('popup_bookmark');
}

export function domBtnSupport() {
    return safeGetElementById('button_support');
}

export function domHtmlContent() {
    return safeGetElementById('html_content');
}

export function domVideoContainer() {
    return safeGetElementById("video_container");
}

export function domImgLogo() {
    return safeGetElementById("img_blast_logo");
}

export function domBtnIndependent() {
    return safeGetElementById("button_independent");
}

export function domBtnMainstream() {
    return safeGetElementById("button_mainstream");
}

export function domBtnPopulist() {
    return safeGetElementById("button_populist");
}

export function domBtnHealth() {
    return safeGetElementById("button_health");
}

export function domBtnNav() {
    return safeGetElementById("button_nav");
}

export function domBtnNews() {
    return safeGetElementById("button_news");
}

export function domBtnCrypto() {
    return safeGetElementById("button_crypto");
}

export function domBtnTrending() {
    return safeGetElementById('button_trending');
}

export function domBtnSearch() {
    return safeGetElementById('btn-search');
}

export function domBtnGuilded() {
    return safeGetElementById('button_guilded');
}

export function domMainPane() {
    return safeGetElementById("main_pane");
}

export function domAdvertFullscreen() {
    return safeGetElementById("advert_fullscreen");
}

export function domSectionVideo() {
    return safeGetElementById("video_section");
}

export function domPopupContent() {
    return safeGetElementById("popup_bookmark_content");
}

export function domSearchInput() {
    return safeGetElementById('search_input');
}

export function domBtnReddit() {
    return safeGetElementById("button_reddit");
}