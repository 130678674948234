// A slide in popup to remind the user to book mark the site.
import { parseMoment } from "./util";
import { db_instance } from "./db";
import { popupBookmarkGet } from "./popup_bookmark";

const ENABLED = true;

const DB_KEY = "popup_support_last_run2";

// Triggers if the scroll position is below this point.
const SCROLL_THRESHOLD_TRIGGER = 2000;

// Three days.
const POPUP_COOLDOWN_MS = 1000 * 60 * 60 * 24 * 3;
// const POPUP_COOLDOWN_MS = 1000 * 60;


function domPopupSupport() {
    const popup_bookmark = document.getElementById('popup_support');
    return popup_bookmark;
}

let gIdDisplayChange = null;

export function popupSupportSet(on) {
    if (gIdDisplayChange) {
        clearTimeout(gIdDisplayChange);
    }
    const popup_bookmark = domPopupSupport();
    if (on) {
        if (popup_bookmark.classList.contains('active')) {
            return;
        }
        popup_bookmark.style.display = 'block';
        gIdDisplayChange = setTimeout(() => {
            popup_bookmark.classList.add('active');
        }, 250);
    } else {
        popup_bookmark.classList.remove('active');
        gIdDisplayChange = setTimeout(() => {
            popup_bookmark.style.display = 'none';
        }, 500);
    }
}

export function popupSupportGet() {
    const popup_bookmark = domPopupSupport();
    return popup_bookmark.classList.contains('active');
}


export async function popupSupportInit() {
    const urlParams = new URLSearchParams(window.location.search);
    const DBG_FORCE_ON = urlParams.get('popup');
    if (!ENABLED && !DBG_FORCE_ON) {
        return;
    }
    // calculate DBG_FORCE_ON from the url params
    // get the url params

    const db = db_instance();
    const last_run = await db.getItem(DB_KEY);
    if (last_run !== null && !DBG_FORCE_ON) {
        const d = parseMoment(last_run);
        const now = parseMoment(Date.now());
        const diff = now - d;
        if (diff < POPUP_COOLDOWN_MS && !DBG_FORCE_ON) {
            // Wait until the coolup time expires.
            return;
        }
    }

    /*
            // Start running the clock now.
        db.setItem(DB_KEY, Date.now());
        last_run = await db.getItem();
        */

    let callback_job = null;
    let last_scroll = 0;
    let last_scroll_time = Date.now();
    let last_popup_bookmark_last_on = Date.now();
    const callback = () => {
        const now = Date.now();
        // get the scroll position from the document window
        const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        if (last_scroll !== scrollTop) {
            last_scroll = scrollTop;
            last_scroll_time = now;
            return;
        }

        if (popupBookmarkGet()) {
            last_popup_bookmark_last_on = now;
            return;
        }

        if ((now - last_popup_bookmark_last_on) < 750) {
            // Wait until the popups stop.
            return;
        }

        const diff = now - last_scroll_time;
        if (diff < 250) {
            // Wait 1/4 second to stop scrolling.
            return;
        }

        if (scrollTop > SCROLL_THRESHOLD_TRIGGER) {
            db.setItem(DB_KEY, now);  // Set the cooldown for the next time.
            popupSupportSet(true);
            clearInterval(callback_job);
            document.getElementById("popup_support_close").addEventListener("click", () => {
                popupSupportSet(false);
            });
        }
    };

    // 
    callback_job = setInterval(callback, 16);
}