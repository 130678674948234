import { restoreScrollPosition } from './scroll_manager';
import { domSectionVideo, domVideoContainer, domHtmlContent } from './dom';
import { roundTo, parseMoment, nowMoment, chunkArray, fastHash } from './util';
import { jsonCacheFetch, CACHE_DISK } from './json_cache_fetcher';
import { trackLink } from './analytics';
import { getAdvertisement, ENABLE_ADVERTISEMENT } from './advert_inline';
import { gridResize } from './grid_layout';

const MAX_VIDEOS_PER_PAGE = 40;
const CHANNEL_MAP_DATA = 'assets/json/nav/index.json';  // Contains link information.
const CHANNEL_MAP_DATA_DURATION = 1000 * 60 * 60;  // Before refetch.
const NUMBER_OF_DUMMY_VIDEOS_DURING_REFRESH = 30;  // This is an over estimate, but works well.
const MIN_VIEWS_FOR_DISPLAY = 20;  // views below this aren't outputted to the DOM.
const gChannelMap = new Map();
let gInitFinished = false;
let gPendingPopulateCallbacks = null;
let gJobIdCurrentWork = null;

function makeVideoId(video_info) {
    const out = fastHash(`${video_info.channel_name}${video_info.title}`);
    return out;
}

// Maps large view numbers to easy to read versions. Example:
//  1,000,000 => 1M
//  100,000, => 100K
function formatViews(v) {
    if (v >= 1000000) {
        return `${roundTo(v / 1000000, 1)}M`;
    }
    if (v > 1000) {
        v = parseFloat(v) / 1000; // eslint-disable-line
        if (v >= 80) {
            v = roundTo(v, 0); // eslint-disable-line
        } else {
            v = roundTo(v, 1); // eslint-disable-line
        }
        return `${v}K`;
    }
    return `${v}`;
}

function makeDateString(video_info, now) {
    if (video_info.date_published.length === 0) {
        return "";
    }
    // Grab the best date and load it. Note that this mess of detection
    // mechanisms is to live-debug the app, which shows NaN for some of the
    // bitchute videos for iOS.
    const date_video_published = parseMoment(video_info.date_published);
    let differenceInMs = now.diff(date_video_published); // diff yields milliseconds
    if (Number.isNaN(differenceInMs)) {
        console.log('Nan detected for differenceInMs for:', video_info);
        differenceInMs = 0;
    } else if (differenceInMs < 0) {
        console.log('differenceInMs was negative:', differenceInMs);
        differenceInMs = 0;
    }
    if (differenceInMs === 0) {
        differenceInMs = now.diff(parseMoment(video_info.date_discovered));
        if (Number.isNaN(differenceInMs) || differenceInMs < 0) {
            differenceInMs = 0;
        }
    }
    const differenceInMinutes = Math.trunc(differenceInMs / (1000 * 60));
    const differenceInHours = Math.trunc(differenceInMs / (1000 * 60 * 60));
    const differenceInDays = Math.trunc(differenceInMs / (1000 * 60 * 60 * 24));
    if (differenceInDays > 1.0) {
        const days_int = parseInt(`${differenceInDays}`, 10);
        if (days_int === 1) {
            return "1 day ago";
        }
        return `${days_int} days ago`;
    }
    if (differenceInMinutes < 60) {
        if (differenceInMinutes !== 1) {
            return `${differenceInMinutes} minutes ago`;
        }
        return `${differenceInMinutes} minute ago`;
    }
    let hours = roundTo(differenceInHours, 1);
    if (hours > 5) {
        hours = roundTo(hours, 0);
    }
    let hours_str = `${hours}`;
    if (hours_str.endsWith(".0")) {
        hours_str = hours_str.substring(0, hours_str.length - 2);
    }
    if (hours > 1) {
        return `${hours_str} hours ago`;
    }
    return `${hours_str} hour ago`;

}

// Attach a function onto the global scope that we may call
// it when the <img> loads.
window.component_video_onImageLoad = (img) => {
    const defered_title = img.getAttribute('data-title');
    if (defered_title) {
        img.title = defered_title;  // eslint-disable-line
    }
};

function formatDuration(durationSeconds) {
    const hours = Math.trunc(durationSeconds / 3600);
    durationSeconds -= hours * 3600;  // eslint-disable-line
    const minutes = Math.trunc(durationSeconds / 60);
    durationSeconds -= minutes * 60;  // eslint-disable-line
    let out = "";
    if (hours > 0) {
        // pad hours with leading zeros
        out += `${hours < 10 ? '0' : ''}${hours}:`;
    }
    // pad minutes with leading zeros
    out += `${minutes < 10 ? '0' : ''}${minutes}:`;
    // pad seconds with leading zeros
    out += `${durationSeconds < 10 ? '0' : ''}${durationSeconds}`;
    // if out starts with 00: then remove the leading 00:
    if (out.startsWith('00:')) {
        out = out.substring(3);
    }
    if (out.length === 2) {
        out = `0:${out}`;
    }
    return out;
}

function genVideoContainer(video_info, now) {
    // Html for the non-ad container
    const html_regular = `
    <article class="video-container" id="{{video_id}}" published-date="{{published_date}}">
        <a href="{{url}}" target="_blank" class="thumbnail" data-duration="{{duration}}">
            <img onload="component_video_onImageLoad(this)" data-title="{{description}}" class="thumbnail-image" lazy-src="{{img_src}}" width="{{img_width}}" height="{{img_height}}"/>
        </a>
        <div class="video-bottom-section">
            <!--a href="{{url}}">
            <img class="channel-icon" src="http://unsplash.it/36/36?gravity=center" />
            </a-->
            <div class="video-details">
                <a href="{{url}}" target="_blank" class="video-title">{{title}}</a>
                <a href="{{channel_hash}}" class="video-channel-name">{{channel_name}}</a>
                <div class="video-metadata">
                    {{video_footer}}
                </div>
                <div class="video-metadata">
                    <i>
                    <span>Source:</span>
                    <span><a href="{{channel_url}}" class="video-channel-name">{{source}}</a> <a href="{{clip_url}}" target="_blank" class="clip-label">clip</a></span>
                    </i>
                </div>
            </div>
        </div>
    </article>
    `;
    const html_ad = `
    <article class="video-container">
        <div class="thumbnail" data-duration="Promoted">
            <iframe src="{{iframe_src}}" scrolling="no" width="100%" style="border: 2px red solid; overflow-y: hidden; aspect-ratio: 16 / 9; allow="fullscreen";></iframe>
        </div>
        <div class="video-bottom-section">
            <div class="video-details">
                <a href="{{url}}" target="_blank" class="video-title">{{title}}</a>
                <a href="{{channel_hash}}" class="video-channel-name">{{channel_name}}</a>
                <div class="video-metadata" style="display: none;">
                    <i>
                    <span>Source:</span>
                    <span><a href="{{channel_url}}" class="video-channel-name">{{source}}</a></span>
                    </i>
                </div>
            </div>
        </div>
    </article>
    `;
    let { channel_name } = video_info;
    const { iframe_src } = video_info;
    let { channel_url } = video_info;
    let date_published = makeDateString(video_info, now);
    // let description = video_info["description"];
    let { duration } = video_info;
    // let iframe_src = video_info["iframe_src"];
    const { img_src } = video_info;
    const { source } = video_info;
    const { title } = video_info;
    const { url } = video_info;
    const { is_ad } = video_info;
    const views = formatViews(video_info.views);
    let view_snippet = `${views} views`;
    const channel_hash = gChannelMap.get(channel_name);
    const video_id = makeVideoId(video_info);
    if (views === '?' || views === '') {
        view_snippet = '';
    }
    if (duration === "?") {
        duration = "";
    } else {
        duration = formatDuration(duration);
    }
    if (is_ad) {
        date_published = '';
        channel_name = '';
        channel_url = '';
    }

    function sanitize_filename(filepath) {
        return filepath.replace(/[^a-zA-Z0-9_\-\.]/g, '');  // eslint-disable-line
    }
    const outputname = sanitize_filename(`${title.substring(0, 40)}.mp4`);
    // eslint-disable-next-line
    const clip_url = "https://clip.blast.video?url=" + encodeURIComponent(url) + "&outputname=" + encodeURIComponent(outputname);
    // const description = video_info.description === "TODO" ? '' : video_info.description;
    const description = "";  // Disable for now, the feature looks weird.
    const html_template = is_ad ? html_ad : html_regular;

    let video_footer = `
    <span>${view_snippet}</span> • <span>${date_published}</span>
    `;
    if (views < MIN_VIEWS_FOR_DISPLAY) {
        video_footer = `
    <span>${date_published}</span>
    `;
    }
    const resolved_html = html_template
        .replace(/{{title}}/g, title)
        .replace(/{{img_src}}/g, img_src)
        .replace(/{{img_width}}/g, video_info.img_width)
        .replace(/{{img_height}}/g, video_info.img_height)
        .replace(/{{channel_name}}/g, channel_name)
        .replace(/{{channel_url}}/g, channel_url)
        .replace(/{{source}}/g, source)
        .replace(/{{url}}/g, url)
        .replace(/{{video_footer}}/g, video_footer)
        .replace(/{{duration}}/g, duration)
        .replace(/{{channel_hash}}/g, channel_hash)
        .replace(/{{iframe_src}}/g, iframe_src)
        .replace(/{{video_id}}/g, video_id)
        .replace(/{{description}}/g, description)
        .replace(/{{published_date}}/g, video_info.published_date)
        .replace(/{{clip_url}}/g, clip_url);
    return resolved_html;
}

function isPlaceholderData(video_list) {
    let count_invalid = 0;
    video_list.forEach(vid => {
        if (vid.title.length === 0) {
            count_invalid++;
        }
    });
    const valid_ratio = 1 - (count_invalid / video_list.length);
    // If more than 50% are invalid than this is placeholder data.
    return valid_ratio < .50;
}

function genVideoContainerHtmls(json_data, sort) {
    const now = nowMoment();
    let data = null;
    if (typeof (json_data) === "string") {
        data = JSON.parse(json_data);
    } else {
        data = json_data;
    }
    // In some jsons, the content is located at the root, at others it's located
    // in the "content" key.
    const video_list = data.content !== undefined ? data.content : data;
    function sort_by_date_published(item0, item1) {
        const a = parseMoment(item0.date_published);
        const b = parseMoment(item1.date_published);
        if (a > b) {
            return -1;
        }
        if (a === b) {
            return 0;
        }
        return 1;
    }

    // sort according to freshest first.
    if (sort && !isPlaceholderData(video_list)) {
        video_list.sort(sort_by_date_published);
    }
    if (ENABLE_ADVERTISEMENT) {
        const advert = getAdvertisement();
        video_list.unshift(advert);
    }
    const video_htmls = [];
    video_list.forEach((video_info) => {
        const video_html = genVideoContainer(video_info, now);
        video_htmls.push(video_html);
    });
    return video_htmls;
}

function userActionWasBackButton() {
    // Returns true if the user hit the back button, false otherwise.
    if (window.performance && window.performance.navigation.type === window.performance.navigation.TYPE_BACK_FORWARD) {
        return true;
    }
    return false;
}

function attachLinkTracking(dom) {
    const video_titles = dom.querySelectorAll('.video-title');
    video_titles.forEach(elem => {
        elem.addEventListener("click", () => {
            trackLink(`title - click: ${elem.href}`);
        });
    });
    // Attach onclick functions to video thumbnail image.
    const thumbnail_imgs = dom.querySelectorAll('.thumbnail');
    thumbnail_imgs.forEach(elem => {
        elem.addEventListener("click", () => {
            trackLink(`thumbnail - click: ${elem.href}`);
        });
    });
    // Attach video channel name.
    const video_channel_names = dom.querySelectorAll('.video-channel-name');
    video_channel_names.forEach(elem => {
        elem.addEventListener("click", () => {
            trackLink(`channel - click: ${elem.href}`);
        });
    });

    dom.querySelectorAll(".clip-label").forEach(elem => {
        elem.addEventListener("click", () => {
            trackLink(`clip - click: ${elem.href}`);
        });
    });

}

function scheduleWork(work_q) {
    if (gJobIdCurrentWork !== null) {
        console.log("Warning, already scheduled work, cancelling previous job.");
        clearInterval(gJobIdCurrentWork);
        gJobIdCurrentWork = null;
    }
    gJobIdCurrentWork = setInterval(() => {
        if (work_q.length === 0) {
            clearInterval(gJobIdCurrentWork);
            gJobIdCurrentWork = null;
            return;
        }
        const work = work_q.shift();
        work();
    });
}


function ingestJsonVideo(json_data, sort) {
    console.log('ingestJsonVideo')
    // eslint-disable-next-line
    sort = (typeof sort !== 'undefined') ? sort : true;  // default to true
    const video_htmls = genVideoContainerHtmls(json_data, sort);
    let video_html = "";
    video_htmls.forEach((html) => { video_html += html; });
    const video_section = domSectionVideo();
    if (gJobIdCurrentWork) {
        clearInterval(gJobIdCurrentWork);
        gJobIdCurrentWork = null;
    }
    video_section.innerHTML = "";
    // Create a temporary container and parse the html, then attach link tracking
    // and then move the elements to the real container.
    const tmp_container = document.createElement('div');
    tmp_container.innerHTML = video_html;
    // Attach onclick functions to video titles.
    attachLinkTracking(tmp_container);
    // Now paginate the videos. This can probably be done in a cleaner way, but I don't know
    // how at this time.
    const video_doms = [];
    while (tmp_container.lastChild) {
        video_doms.push(tmp_container.lastChild);
        tmp_container.removeChild(tmp_container.lastChild);
    }
    video_doms.reverse();

    const work_q = [];
    chunkArray(video_doms, MAX_VIDEOS_PER_PAGE).forEach((video_doms_chunk) => {
        const work = () => {
            for (let i = 0; i < video_doms_chunk.length; i++) {
                video_section.appendChild(video_doms_chunk[i]);
            }
        };
        work_q.push(work);
    });
    scheduleWork(work_q);
}

export function ingestHtml(html) {
    clearInterval(gJobIdCurrentWork);
    gJobIdCurrentWork = null;
    domVideoContainer().style.display = "none";
    domHtmlContent().style.display = "block";
    domHtmlContent().innerHTML = html;
}

export function ingestEmbed(url) {
    const html = `<embed src="${url}" width="100%" style="height:100vh; overflow: hidden; border: none"></embed>`;
    ingestHtml(html);
}

function populateVideoUiFromJsonData(json_data, sort) {
    console.log('populateVideoUiFromJsonData')
    clearInterval(gJobIdCurrentWork);
    gJobIdCurrentWork = null;
    domHtmlContent().style.display = "none";
    domVideoContainer().style.display = "block";
    const work = () => {
        console.log('populateVideoUiFromJsonData -> work')
        ingestJsonVideo(json_data, sort);
        gridResize();
        if (userActionWasBackButton()) {
            restoreScrollPosition();
        }
    };
    if (gInitFinished) {
        work();
    } else {
        if (gPendingPopulateCallbacks === null) {
            gPendingPopulateCallbacks = [];
        }
        gPendingPopulateCallbacks.push(work);
    }
}

function clearVideoUi() {
    console.log('clearVideoUi')
    if (!gInitFinished) {
        console.error('clearVideoUi -> !gInitFinished')
        return;
    }
    const content = [];
    for (let i = 0; i < NUMBER_OF_DUMMY_VIDEOS_DURING_REFRESH; ++i) {
        content.push({
            'channel_name': '',
            'channel_url': '',
            'date_published': '',
            'duration': '?',
            'img_src': '',
            'source': '',
            'title': '',
            'url': '',
            'views': '',
        });
    }
    const payload = { 'content': content };
    populateVideoUiFromJsonData(JSON.stringify(payload));
}


function initVideoUi() {
    jsonCacheFetch(CHANNEL_MAP_DATA, CHANNEL_MAP_DATA_DURATION, CACHE_DISK, (json) => {
        gInitFinished = true;
        json.forEach((row) => {
            gChannelMap.set(row[0], `#${row[1]}`);
        });
        if (gPendingPopulateCallbacks) {
            const callbacks = gPendingPopulateCallbacks;
            gPendingPopulateCallbacks = null;
            callbacks.forEach(cb => cb());
        }
    });
}

export { populateVideoUiFromJsonData, clearVideoUi, initVideoUi };
