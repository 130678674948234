
import { domMainPane, domAdvertFullscreen } from './dom';
import { parseStringBool, isMobile, cssSetGlobalVar } from './util';

// Still under test!
// const URL = "https://rumble.com/embed/vjzhz8/?pub=7a20&rel=0&autoplay=2";
const URL = "https://www.bitchute.com/embed/ZNwOPNrJxaSQ/";
const ENABLE_ADVERT_FULLSCREEN = false;
const DELAY_ADVERT_KILLED = 300;
const DELAY_ACTIVATE_ADVERT = 200;
const AD_TIME_SECONDS = 5;
const THRESHOLD_BOTTOM_SKIP_AD_PLACEMENT = 450;

function init_css() {
    const sheet = document.createElement('style');
    sheet.innerHTML = `
    #advert_fullscreen {
        visibility: hidden;
        position: fixed;
        background: rgba(26, 0, 0, 0);
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        z-index: 20000;  /* Must be higher than nav sidebar */
        opacity: 0;
        transition: all .2s ease-in;
    }
    #advert_fullscreen.active {
        display: block;
        visibility: visible;
        opacity: 1;
        transition: all .2s ease-in;
    }
    #advert_div {
        position: fixed;
        border: 1;
        width: var(--advert-fullscreen-width);
        height: var(--advert-fullscreen-height);
        left: calc(50vw - var(--advert-fullscreen-width)/2);
        top: calc(50vh - var(--advert-fullscreen-height)/2);
    }

    #btn_ad_skip {
        font-size: 18pt;
        background: rgba(0, 0, 0, 0.5);
        color: grey;
        border: 1;
        border-color: grey;
        border-style: solid;
        transition: all .2s ease-in;
    }
    #btn_ad_skip.inline {
        position: absolute;
        bottom: 20px;
        right: 20px;
        margin: 20px 20px 20px 20px;
    }
    #btn_ad_skip.bottom {
        position: block;
        width: 100%;
    }
    #btn_ad_skip.enabled {
        background: rgba(0, 0, 0, 0);
        color: white;
        border-color: white;
        transition: all .2s ease-in;
    }
    #btn_ad_skip.enabled:hover {
        background-color: rgba(128, 128, 128, 1);
        transition: all .2s ease-in;
    }
    #btn_ad_skip.enabled:focus {
        background-color:white;
    }
    `;
    document.head.appendChild(sheet);
}

export function initAdvertFullscreen() {
    const url_params = new URLSearchParams(window.location.search);
    const force_ad = parseStringBool(url_params.get('ad'));
    if (!ENABLE_ADVERT_FULLSCREEN && !force_ad) {
        return;
    }
    init_css();
    const main_pane = domMainPane();
    const advert_content = domAdvertFullscreen();
    let finished = false;  // Enforces single use.
    let countdown_task = null;
    let resize_task = null;
    const prevScrollState = document.body.style.overflow;
    // call this to Disable
    function disableScroll() {
        document.body.style.overflow = "hidden";
    }
    // call this to Enable
    function enableScroll() {
        document.body.style.overflow = prevScrollState;
    }
    // Only call once and after insert_advert() is called, due to delayed action.
    function kill_advert() {
        if (finished) {
            return;
        }
        main_pane.classList.remove('inactive');
        advert_content.classList.remove('active');
        enableScroll();
        // Totally destroy resources.
        setTimeout(() => { advert_content.innerHTML = ""; }, DELAY_ADVERT_KILLED);
        clearInterval(countdown_task);
        finished = true;
    }
    // Only call once, due to delayed action.
    function insert_advert() {
        if (finished) {
            return;
        }
        disableScroll();
        const div_content = `
			<div id="advert_div">
				<iframe width="100%" height="100%" src="${URL}"></iframe>
				<div id="div_btn_ad_skip">
					<input disabled type="button" id="btn_ad_skip" value="Skip"></input>
				</div>
			</div>`;
        advert_content.innerHTML = div_content;
        // const advert_div = document.getElementById('advert_div');
        const btn_add_skip = document.getElementById('btn_ad_skip');
        btn_add_skip.onclick = kill_advert;
        setTimeout(() => {
            main_pane.classList.add('inactive');
            advert_content.classList.add('active');
        }, DELAY_ACTIVATE_ADVERT);
        // 30fps countdown.
        const time_start = new Date().getTime();
        function loop_countdown() {
            const time_now = new Date().getTime();
            const dtime_secs = Number.parseInt((time_now - time_start) / 1000, 10);
            const time_remaining = AD_TIME_SECONDS - dtime_secs;
            if (time_remaining < 0) {
                clearInterval(countdown_task);
                countdown_task = null;
                btn_add_skip.value = `Skip now`;
                btn_add_skip.classList.add('enabled');
                btn_add_skip.disabled = false;
            } else {
                btn_add_skip.value = `Skip in ${time_remaining}`;
            }
        }
        const ratio = 120 / 214;
        const is_ios = isMobile();
        let last_width = 0;
        function loop_resize() {
            if (finished) {
                clearInterval(resize_task);
                return;
            }
            const curr_w = window.innerWidth;
            if (curr_w !== last_width) {
                last_width = curr_w;
                let mult = .8;
                if (curr_w < THRESHOLD_BOTTOM_SKIP_AD_PLACEMENT || is_ios) {
                    btn_add_skip.classList.add('bottom');
                    btn_add_skip.classList.remove('inline');
                    mult = 1.0;
                } else {
                    btn_add_skip.classList.add('inline');
                    btn_add_skip.classList.remove('bottom');
                }
                const w = curr_w * mult;
                const h = ratio * w;
                cssSetGlobalVar('--advert-fullscreen-width', `${w}px`);
                cssSetGlobalVar('--advert-fullscreen-height', `${h}px`);
            }
        }
        countdown_task = setInterval(loop_countdown, 16);
        resize_task = setInterval(loop_resize, 16);
    }
    setTimeout(insert_advert, 3000);
}
