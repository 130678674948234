
import {
    domBtnSupport,
    domBtnIndependent,
    domBtnMainstream,
    domBtnPopulist,
    domBtnCrypto,
    domBtnNews,
    domBtnHealth,
    domBtnTrending,
    domSearchInput,
    domBtnNav,
    // domBtnTelegram,
    domBtnReddit,
    domBtnGuilded,
} from './dom';

import { addRouteHandler, setRoute, domNetworkButtonSetSelected } from './router';
import { jsonCacheFetch, CACHE_DISK, CACHE_INMEMORY } from './json_cache_fetcher';
import { parseMoment } from './util';
import { sideBarShow } from './sidebar';
import { trackLink } from './analytics';
import { ingestEmbed } from './component_video';
import { trendingReorder } from './trending';

const URL_BUILD_INFO = 'assets/json/buildinfo.json';

// Cached json items only last 5 minutes.
const BUILD_INFO_EXPIRES_MS = 1000 * 60 * 5;
const ONE_HOUR = 1000 * 60 * 60;
let onVideoDataLoadedCallback = null;

let gAssetTimestamp = null;

const gAssetValidationMap = new Map();


function fetchBuildAssetIfInvalid(asset_url, on_completion) {
    function onBuildInfo(build_info) {
        const buildtime = parseMoment(build_info.buildtime);
        const build_timestamp = buildtime.unix();
        // Update global time stamp if changed.
        if (build_timestamp !== gAssetTimestamp) {
            gAssetTimestamp = build_timestamp;
        }
        // If there's a refresh in the build time, then update the asset immediatly.
        // Otherwise fetch it gently from the cache with a long expiration time.
        const build_time = gAssetValidationMap[asset_url];
        if (build_time !== build_timestamp) {
            gAssetValidationMap[asset_url] = build_timestamp;
            // Force fetch.
            const expire_time = build_time !== undefined ? 0 : ONE_HOUR / 15;
            jsonCacheFetch(asset_url, expire_time, CACHE_DISK, on_completion);
        } else {
            jsonCacheFetch(asset_url, ONE_HOUR, CACHE_DISK, on_completion);
        }
    }
    jsonCacheFetch(URL_BUILD_INFO, BUILD_INFO_EXPIRES_MS, CACHE_INMEMORY, onBuildInfo);
}


function makeButtonMap() {
    const data = [];
    data.push(["#independent", ["/assets/json/independent.json.gz", domBtnIndependent()]]);
    data.push(["#mainstream", ["/assets/json/mainstream.json.gz", domBtnMainstream()]]);
    data.push(["#populist", ["/assets/json/populist.json.gz", domBtnPopulist()]]);
    data.push(["#crypto", ["/assets/json/crypto.json.gz", domBtnCrypto()]]);
    data.push(["#news", [null, domBtnNews()]]);
    data.push(["#health", ["/assets/json/health.json.gz", domBtnHealth()]]);
    data.push(["#trending", ["/assets/json/trending.json.gz", domBtnTrending()]]);
    const m = new Map(data);
    return m;
}

function makeTelegramMap() {  // eslint: disable=no-unused-vars
    const data = [];
    data.push(["#independent", "https://t.me/blast_video_independent"]);
    data.push(["#mainstream", "https://t.me/blast_video_mainstream"]);
    data.push(["#populist", "https://t.me/blast_video_populist"]);
    data.push(["#crypto", "https://t.me/blast_video_crypto"]);
    data.push(["#health", "https://t.me/blast_video_health"]);
    const m = new Map(data);
    return m;
}

const gButtonMap = makeButtonMap();
// eslint-disable-next-line no-unused-vars
const gTelegramMap = makeTelegramMap();

function onTrendingLoaded(data) {
    let { content } = data;
    content = trendingReorder(content);
    onVideoDataLoadedCallback({ content });
}

function onButtonAction(route) {
    domSearchInput().value = '';
    const tup = gButtonMap.get(route);
    const asset_url = tup[0];
    const btn = tup[1];
    domNetworkButtonSetSelected(btn);  // Set the button that's currently active.
    let callback = onVideoDataLoadedCallback;
    if (route === "#trending") {
        callback = onTrendingLoaded;
    }
    if (route === "#news") {
        callback = () => { ingestEmbed("/assets/news.html"); };
    }
    if (asset_url === null) {
        // Try deferred rather than immediate timeout to try and fix iframe news censored issue
        // callback();
        setTimeout(() => { callback(); }, 0);
        return;
    }
    fetchBuildAssetIfInvalid(asset_url, callback);
}

function initRouteHandlers() {
    function on_click(btn, route) {
        if (btn.classList.contains("active")) {
            return;
        }
        // TODO: rename network -> feed.
        domNetworkButtonSetSelected(btn);
        setTimeout(() => {
            window.scroll({ top: 0, behavior: "smooth" });
            setTimeout(() => { setRoute(route); });
        });
    }
    // Set the onclick functions so that a callback to set the route is called.
    gButtonMap.forEach((vals, route) => {
        // const asset = vals[0];
        const btn = vals[1];
        btn.onclick = () => { on_click(btn, route); };
        addRouteHandler(route, () => onButtonAction(route));
    });
}

/*
function onTelegramClicked() {
    const hash = `#${window.location.href.split("#")[1]}`;
    const link = gTelegramMap.get(hash);
    if (link) {
        trackLink(link);
        window.open(link, "_blank");
    } else {
        console.log("Telegram button disabled");
    }
}
*/

function updateStrickyBarButtonToolTipPlacement() {
    const dom_tooltips = document.querySelectorAll(".tooltip, .sticky-bar-btn");
    dom_tooltips.forEach(tooltip => {
        let title = tooltip.getAttribute("title");
        const has_data_title = tooltip.hasAttribute("data-title");
        if (!title && !has_data_title) {
            return;  // Skipping tooltips without title.
        }
        if (!has_data_title) {
            tooltip.setAttribute("data-title", title);
        }
        const rect = tooltip.getBoundingClientRect();
        const top = rect.top + document.body.scrollTop;
        if (top > rect.height / 2) {
            tooltip.setAttribute("title", "");
        } else {
            title = tooltip.getAttribute("data-title");
            tooltip.setAttribute("title", title);
        }
    });
}

export function initButtonActions(on_video_loaded_callback) {
    onVideoDataLoadedCallback = on_video_loaded_callback;
    initRouteHandlers();
    domBtnNav().onclick = () => { sideBarShow(true); };
    // domBtnTelegram().onclick = onTelegramClicked;
    domBtnReddit().onclick = () => {
        const link = "https://www.reddit.com/r/Blast_Video/";
        trackLink(link);
        window.open(link, "_blank");
    };
    domBtnGuilded().onclick = () => {
        const link = "https://www.guilded.gg/BlastVideo/";
        trackLink(link);
        window.open(link, "_blank");
    };

    domBtnSupport().onclick = () => {
        const link = "https://givesendgo.com/BlastVideo";
        trackLink(link);
        window.open(link, "_blank");
    };
    window.onscroll = updateStrickyBarButtonToolTipPlacement;
}