

/*
function sort_by_date_published(item0, item1) {
    const a = parseMoment(item0.date_published);
    const b = parseMoment(item1.date_published);
    if (a > b) {
        return -1;
    }
    if (a === b) {
        return 0;
    }
    return 1;
}
*/

import { parseMoment, nowMoment } from './util';


function partitionByDay(content) {
    const now_moment = nowMoment();
    let out = new Map();
    for (let i = 0; i < content.length; ++i) {
        const item = content[i];
        const date_published = parseMoment(item.date_published);
        const diff = now_moment.diff(date_published, 'days');
        if (out.get(diff) === undefined) {
            out.set(diff, []);
        }
        out.get(diff).push(item);
    }
    // Sorts everything by the day key.
    out = new Map([...out.entries()].sort());
    return out;
}

export function trendingReorder(content) {
    const partitioned_map = partitionByDay(content);
    const out = [];
    for (const [day, vidlist] of partitioned_map.entries()) {  // eslint-disable-line
        vidlist.forEach(vid => { out.push(vid); });
    }
    return out;
}