

import { jsonCacheFetch, CACHE_DISK } from './json_cache_fetcher';
import { clearScrollPosition } from './scroll_manager';
import {
    domBtnIndependent,
    domBtnMainstream,
    domBtnPopulist,
    domBtnCrypto,
    domBtnNews,
    domBtnHealth,
    domBtnTrending,
    domSearchInput,
    // domBtnTelegram,
} from './dom';
// Cached json items only last 5 minutes.
const JSON_DATA_EXPIRES_MS = 1000 * 60 * 5;

const CHANNEL_MAP_DATA = 'assets/json/nav/index.json';  // Contains link information.
const CHANNEL_MAP_DATA_DURATION = 1000 * 60 * 60;  // Before refetch.

const gChannelMap = new Map();
const gRouteHandlers = new Map();

let gOnVideoDataFetchStarted = null;
let gOnVideoDataLoaded = null;

function domBtnNetworkArray() {
    const out = [
        domBtnIndependent(),
        domBtnMainstream(),
        domBtnPopulist(),
        domBtnCrypto(),
        domBtnNews(),
        domBtnHealth(),
        domBtnTrending(),
    ];
    return out;
}

// button_active can be null.
function domNetworkButtonSetSelected(button_active) {
    const all_btns = domBtnNetworkArray();
    all_btns.forEach(btn => {
        const cl = btn.classList;
        cl.remove("active");
    });
    if (button_active) {
        button_active.classList.add("active");
    }
}

function addRouteHandler(key, fcn) {
    gRouteHandlers.set(key, fcn);
}

function setRoute(theHash) {
    window.location.hash = theHash;
}

// function hasTelegramButton(route) {
//     const no_telegram_button = (route === "#search" || route === "#trending" || route === "#news");
//     return !no_telegram_button;
// }

function defaultButtionClick() {
    domBtnTrending().click();  // TODO: Is the best way? Seems hacky,
    // domBtnIndependent().click();  // TODO: Is the best way? Seems hacky,
}

function processRoute(route) {
    console.log("processRoute", route);
    // Remove the telegram button for routes #trending and #search.
    // const telegram_button_mode = hasTelegramButton(route) ? "block" : "none";
    // domBtnTelegram().style.display = telegram_button_mode;
    if (route === "#search") {
        gOnVideoDataFetchStarted();
        domNetworkButtonSetSelected(null);  // Deselect any button that was on.
        const vid_list_json = sessionStorage.getItem('search-cache');
        const prev_search_value = sessionStorage.getItem('search-input-value');
        if (prev_search_value) {
            domSearchInput().value = prev_search_value;
            const vid_list = JSON.parse(vid_list_json);
            gOnVideoDataLoaded(vid_list, false);
        } else {
            clearScrollPosition();
            // Default if nothing selected.
            defaultButtionClick();
        }
    } else if (gChannelMap.has(route)) {  // Handle channel navigation
        // Remove search input.
        domSearchInput().value = '';
        clearScrollPosition();
        gOnVideoDataFetchStarted();
        domNetworkButtonSetSelected(null);
        jsonCacheFetch(
            gChannelMap.get(route),
            JSON_DATA_EXPIRES_MS,
            CACHE_DISK,
            gOnVideoDataLoaded);
        setTimeout(() => {
            window.scroll({ top: 0, behavior: "smooth" });
        });
    } else if (gRouteHandlers.has(route)) {  // Handle button map.
        gOnVideoDataFetchStarted();
        const callback = gRouteHandlers.get(route);
        callback();
    } else {
        clearScrollPosition();
        // Default if nothing selected.
        defaultButtionClick();
    }
}

function handleRouteChange(hash) {  // eslint-disable-line
    console.log("handleRouteChange", hash);
    setRoute(hash);
    processRoute(hash);
}


function initRouting(on_video_data_fetch_started, on_video_data_loaded) {
    gOnVideoDataFetchStarted = on_video_data_fetch_started;
    gOnVideoDataLoaded = on_video_data_loaded;
    // If the cache is loaded with the data then this will execute immediatly.
    jsonCacheFetch(CHANNEL_MAP_DATA, CHANNEL_MAP_DATA_DURATION, CACHE_DISK, (json) => {
        // Initialize the channel map.
        console.log("fetching channel map:", json)
        json.forEach((row) => {
            const url = `https://db.blast.video/json?channel=${encodeURIComponent(row[0])}&days=90&limit=100`;
            const key = `#${row[1]}`;
            console.log("setting key", key, "to url", url)
            gChannelMap.set(key, url);
        });
        window.onhashchange = (e) => {
            const next = e.newURL;
            const last = e.oldURL;
            if (last === next) {
                console.log("hash change event with no change.")
                return;
            }
            const hash = `#${next.split("#")[1]}`;
            processRoute(hash);
        };
        const hash = `#${window.location.hash.split("#")[1]}`;
        processRoute(hash);
    });
}

export { setRoute, initRouting, handleRouteChange, addRouteHandler, domNetworkButtonSetSelected };
