

// How long to save the scrollPosition before expiring it.
const SCROLL_POSITION_EXPIRES_MINS = 60;

function clearScrollPosition() {
    sessionStorage.removeItem("scrollPosition");
    sessionStorage.removeItem("scrollPositionTimestamp");
}

// ScrollPosition save/restore/clear
// Note: this still has problems, it might be better to record the video that was clicked
// or was in view and then restore the position so that those videos come back into view.
// Luckly jquery has a solution for this:
//   https://www.jqueryscript.net/other/check-element-in-view.html
function saveScrollPosition() {
    sessionStorage.setItem("scrollPosition", `${  document.documentElement.scrollTop}`);
    sessionStorage.setItem("scrollPositionTimestamp", `${  new Date().getTime()}`); // Time in ms.
}

function restoreScrollPosition() {
    if (sessionStorage.scrollPosition) {
        if (sessionStorage.scrollPositionTimestamp) {
            const now = new Date().getTime();
            const prev_now = parseInt(sessionStorage.getItem("scrollPositionTimestamp"), 10);
            const duration_ms = now - prev_now;

            const duration_mins = Math.trunc(duration_ms / (1000 * 60));
            if (duration_mins > SCROLL_POSITION_EXPIRES_MINS) {
                return;
            }
        }
        document.documentElement.scrollTop = sessionStorage.scrollPosition;
        clearScrollPosition();
    }
}

export { restoreScrollPosition, saveScrollPosition, clearScrollPosition };