const dayjs = require('dayjs');

function cyrb53(str, seed = 0) {
    // Hashing function tuned to javascript to return 53 bit integers.
    /* eslint no-bitwise: ["error", { "allow": ["^", ">>>", "&"] }] */
    let h1 = 0xdeadbeef ^ seed;
    let h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

function fastHash(str, seed = 0) {
    return cyrb53(str, seed);
}


function timeMs() {
    return new Date().getTime();
}

function chunkArray(array, chunkSize) {
    const chunkedArr = [];
    let counter = 0;
    while (counter < array.length) {
        chunkedArr.push(array.slice(counter, counter + chunkSize));
        counter += chunkSize;
    }
    return chunkedArr;
}

function roundTo(n, digits) {
    let negative = false;
    if (digits === undefined) {
        digits = 0; // eslint-disable-line
    }
    if (n < 0) {
        negative = true;
        n *= -1; // eslint-disable-line
    }
    const multiplicator = 10 ** digits;
    n = parseFloat((n * multiplicator).toFixed(11)); // eslint-disable-line
    n = (Math.round(n) / multiplicator).toFixed(digits); // eslint-disable-line
    if (negative) {
        n = (n * -1).toFixed(digits); // eslint-disable-line
    }
    return n;
}

function replaceAll(input_str, needle_str, new_str) {
    let out = input_str;
    while (true) {  // eslint-disable-line
        out = input_str.replace(needle_str, new_str);
        if (out === input_str) {
            return out;
        }
        input_str = out; // eslint-disable-line
    }
}

function isUndefined(obj) {
    return (typeof obj === "undefined");
}

// Given a table form of the video list, where the first
// row is the headers, and the rest of the rows are data
// (think CSV file format, but in json), this function will
// return a list of dictionaries, representing the decompressed
// version of the videos.
function reflateVideoInfos(video_table) {
    const column_names = video_table[0];
    const out = [];
    for (let i = 1; i < video_table.length; ++i) {
        const curr_row = video_table[i];
        const vid_info = {};
        for (let j = 0; j < column_names.length; ++j) {
            vid_info[column_names[j]] = curr_row[j];
        }
        out.push(vid_info);
    }
    return out;
}

function parseMoment(date_published) {
    try {
        // Check if date_published is already a Day.js object
        if (dayjs.isDayjs(date_published)) {
            return date_published;
        }

        // Parse with dayjs
        const out = dayjs(date_published);
        if (!out.isValid()) {
            throw new Error('Invalid date');
        }

        return out;
    } catch (e) {
        const typeStr = typeof date_published;
        console.error('Error parsing date_published:', date_published, e, typeStr);
        throw e;
    }
}


function nowMoment() {
    return dayjs();
}

function isMobile() {
    // First step.
    const is_mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (is_mobile) {
        return is_mobile;
    }
    // Second test ripped from the internet.
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
}

function isIPhone() {
    // First step.
    const is_iphone = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    if (is_iphone) {
        return is_iphone;
    }
    // Second test ripped from the internet.
    return [
        'iPhone Simulator',
        'iPod Simulator',
        'iPhone',
    ].includes(navigator.platform);
}


function addScript(url, async, callback) {
    const s = document.createElement("script");
    const head = document.getElementsByTagName('head')[0];
    s.type = "text/javascript";
    s.src = url;
    if (async) {
        s.setAttribute('async', 'true');
    }
    if (callback) {
        s.onload = () => { callback(); };
    }
    head.appendChild(s);
}

function loadStyle(url) {
    return new Promise((resolve, reject) => {  // eslint-disable-line
        const link = document.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.onload = () => { resolve(); console.log(`style ${url} has loaded`); };
        link.href = url;

        const headScript = document.querySelector('script');
        headScript.parentNode.insertBefore(link, headScript);
    });
}

class Timer {
    constructor() {
        this.expired_time = new Date().getTime();
    }

    active() {
        return new Date().getTime() < this.expired_time;
    }

    expired() {
        return !this.active();
    }

    set_expired_time(exp_time) {
        this.expired_time = exp_time;
    }
}


function cssGetGlobalVar(key) {
    const styles = getComputedStyle(document.documentElement);
    const out = styles.getPropertyValue(key);
    return out;
}

function cssSetGlobalVar(key, value) {
    document.documentElement.style.setProperty(key, value);
}


function parseStringBool(s) {
    switch (s) {
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
            return true;
        default:
            return false;
    }
}

function getMillis() {
    return new Date().getMilliseconds();
}

export { isIPhone, parseStringBool, cssSetGlobalVar, cssGetGlobalVar, Timer, loadStyle, addScript, isMobile, nowMoment, fastHash, timeMs, chunkArray, roundTo, replaceAll, isUndefined, reflateVideoInfos, parseMoment, getMillis };
