

import { jsonCacheFetch, CACHE_DISK } from './json_cache_fetcher';
import { domSearchInput, domBtnSearch } from './dom';
import { searchAlgorithm } from './search_algorithm';
import { reflateVideoInfos } from './util';

const SEARCH_JSON_URL = './assets/json/search.json.gz';
const SEARCH_JSON_URL_EXPIRES_MS = 1000 * 60 * 5;

let domSearchInputField = null;
let domButtonSearch = null;
var onSearchResults = () => { // eslint-disable-line
    console.log("onSearchResults not initialized.");
};

let onSearchInitiated = () => {
    console.log("onSearchInitiated not initialized.");
};

function doSearch() {
    onSearchInitiated();
    const query = domSearchInputField.value;
    const callback = (json_data) => {
        const out_table = searchAlgorithm(query, json_data);
        // console.log('Processed entries in', elapsed, 'ms');
        const vid_list = reflateVideoInfos(out_table);
        onSearchResults(query, vid_list);
    };
    // jsonCacheFetch(SEARCH_JSON_URL, SEARCH_JSON_URL_EXPIRES_MS, callback);
    jsonCacheFetch(SEARCH_JSON_URL, SEARCH_JSON_URL_EXPIRES_MS, CACHE_DISK, callback);
}

function ensurePrefetch() {
    // const time_start = timeMs();
    // console.log('Queing prefetch')
    jsonCacheFetch(SEARCH_JSON_URL, SEARCH_JSON_URL_EXPIRES_MS, CACHE_DISK, () => {
        // console.log('Prefetch from keypress completed in', timeMs()-time_start, 'ms');
    });
}

function onKeyPress(evt) {
    const KEY_ENTER = 13;
    if (evt.charCode === KEY_ENTER) {
        doSearch();
    }
}

// on_search_initiated is a function() {}
// on_sarch_results is a function(query, video_list) {}
function initSearch(on_search_initiated, on_search_results) {
    domSearchInputField = domSearchInput();
    domButtonSearch = domBtnSearch();
    domButtonSearch.onclick = doSearch;
    domSearchInputField.onfocus = ensurePrefetch;
    domSearchInputField.addEventListener('keypress', onKeyPress);
    onSearchResults = on_search_results;
    onSearchInitiated = on_search_initiated;
}

export { initSearch };