

import { initResponsiveGridLayout } from "./grid_layout";
import { initSideBar } from './sidebar';
import { initAnalytics } from './analytics';
import { initSearch } from './search';
import { populateVideoUiFromJsonData, clearVideoUi, initVideoUi } from './component_video';
import { initLazyImageLoader } from './lazy_image_loader';
import { initRouting, handleRouteChange } from './router';
import { saveScrollPosition } from './scroll_manager';
import { initButtonActions } from './button_actions';
import { loadStyle } from './util';
import { initAdvertFullscreen } from './advert_fullscreen';
import { initToolTip } from './tooltip';
import { popupBookMarkInit } from './popup_bookmark';
import { popupSupportInit } from './popup_support';

function onSearchResults(query, video_list) {
    if (query.length === 0) {
        handleRouteChange('');
        return;
    }
    const serialized = JSON.stringify({ 'content': video_list });
    sessionStorage.setItem('search-cache', serialized);
    sessionStorage.setItem('search-input-value', query);
    // NOTE: The search results are stored in the localStoarge
    // 'search-cache' and 'search-input-value' which is then re-read
    // in the handleRouteChange.
    // This allows the use of the back button for #search and restoration of
    // search query.
    handleRouteChange('#search');  // Will trigger a reload.
}

function initApp() {
    console.log('initApp')
    setTimeout(() => {
        console.log('load roboto font');
        loadStyle('assets/google_roboto_font.css');
        console.log('load font awesome');
        loadStyle('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.css');
    });

    window.onbeforeunload = saveScrollPosition;

    console.log('initSideBar');
    initSideBar();
    console.log('initToolTip');
    initToolTip();
    console.log('initResponsiveGridLayout');
    initResponsiveGridLayout();
    console.log('initButtonActions');
    initButtonActions(populateVideoUiFromJsonData);
    console.log('initRouting');
    initRouting(clearVideoUi, populateVideoUiFromJsonData);
    console.log('initVideoUi');
    initVideoUi();
    console.log('initSearch');
    initSearch(clearVideoUi, onSearchResults);
    // Execute analyticsTrackThisPage soon, but not immediate.
    console.log('initAnalytics');
    initAnalytics();
    console.log('initLazyImageLoader');
    initLazyImageLoader();
    console.log('initAdvertFullscreen');
    initAdvertFullscreen();
    console.log('popupBookMarkInit');
    popupBookMarkInit();
    console.log('popupSupportInit');
    popupSupportInit();

    /*
    let toggle = false;
    setInterval(() => {
        toggle = !toggle;
        navBarShow(toggle);
    }, 1000);
    */
    console.log('\nblast.video - all rights reserved.\n');
}

export { initApp };
