

// import React from 'react';
// import ReactDOM from 'react-dom';
// import './style.css';
import { initApp } from './src/app';

// const title = 'React with Webpack and Babel';
// ReactDOM.render(
//  <div>{title}</div>,
//  document.getElementById('app')
// );

// console.log('Environment is', process.env.NODE_ENV);
initApp();
