

const fflate = require('fflate');

export function gzipUncompressJson(binary) {
    const uncompressed = fflate.decompressSync(binary);
    const str = fflate.strFromU8(uncompressed);
    const json = JSON.parse(str);
    return json;
}

export async function gzipUncompressJsonAsync(binary) {
    const uncompressed = await fflate.decompress(binary);
    const str = fflate.strFromU8(uncompressed);
    const json = JSON.parse(str);
    return json;
}