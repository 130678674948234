

let isInitialized = false;
let intersectionObserver = null;
let mutationObserver = null;

function domFindAllImgWithLazySrc() {
    const out = [];
    const all_imgs = document.getElementsByTagName('img');
    for (let i = 0; i < all_imgs.length; ++i) {
        const img = all_imgs[i];
        const lazy_src = img.getAttribute("lazy-src");
        const curr_src = img.getAttribute("src");
        if (lazy_src && lazy_src !== curr_src) {
            out.push(img);
        }
    }
    return out;
}

function loadImage(img) {
    setTimeout(() => {
        const lazy_src = img.getAttribute('lazy-src');
        const curr_src = img.getAttribute('src');
        if (lazy_src && lazy_src !== curr_src) {
            img.setAttribute('src', lazy_src);
        }
    });
}


function onIntersectionCallback(entries, observer) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            loadImage(entry.target);
            observer.unobserve(entry.target);
        }
    });
}

function loadLazyImages() {
    if (!isInitialized) {
        console.log("Attempting to call loadLazyImages before it's initialize.");
        return;
    }
    const targetElements = domFindAllImgWithLazySrc();
    targetElements.forEach(elem=>{
        intersectionObserver.observe(elem);
    });
}

function initLazyImageLoader() {
    if (isInitialized) {
        console.log('Attempting to double initialize lazy loader');
        return;
    }
    isInitialized = true;
    const container = document.documentElement || document.body;
    // When document changes, mutationObserver will attach any new images
    // it finds to the IntersectionObserver.
    mutationObserver = new MutationObserver(loadLazyImages);
    mutationObserver.observe(
        container,
        { attributes: true, childList: true, subtree: true });
    intersectionObserver = new IntersectionObserver(
        onIntersectionCallback,
        { rootMargin: "200px" });
}

export { initLazyImageLoader };