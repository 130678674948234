const DISABLE_ADVERTISEMENT = false;

// if date is November 9th, 2022 then enable advertisement
function isAdvertisementEnabled() {
    console.log("isAdvertisementEnabled");
    if (DISABLE_ADVERTISEMENT) {  // eslint no-constant-condition: "error"
        return false;
    }
    const now = new Date();
    const month = now.getMonth();
    const day = now.getDate();
    const year = now.getFullYear();
    console.log(`month: ${month} day: ${day} year: ${year}`);
    if (month === 0 && (day >= 19 && day <= 31) && year === 2023) {
        return true;
    }
    return false;
}

export const ENABLE_ADVERTISEMENT = isAdvertisementEnabled();

function makeBitchuteAdvert(url, imageUrl, videoId, title) {
    // Returns a json representing a video.
    const videoUrl = `https://www.bitchute.com/embed/${videoId}/?autoplay=1&title=0&byline=0&wmode=transparent`;
    const now_date_str = new Date().toISOString();
    const out =
    {
        'channel_name': 'blast.video - help us out',
        "title": title,
        "date_published": now_date_str,
        "date_discovered": now_date_str,
        "date_lastupdated": now_date_str,
        "channel_url": url,
        "source": "mymoviesplus.com",
        "url": url,
        "duration": "",
        "description": "",
        "img_src": imageUrl,
        "img_status": 200,
        "img_width": 640,
        "img_height": 360,
        // All this stuff is to enable auto play.
        "iframe_src": videoUrl,
        "views": "",
        "profile_img_src": "",
        "subtitles_url": "",
        "is_ad": true,
    };
    return out;
}

function makeRumbleVideo(url, imageUrl, videoId, title) {
    // Returns a json representing a video.
    const videoUrl = `https://rumble.com/embed/${videoId}/?pub=4`;
    const now_date_str = new Date().toISOString();
    const out =
    {
        'channel_name': 'blast.video - help us out',
        "title": title,
        "date_published": now_date_str,
        "date_discovered": now_date_str,
        "date_lastupdated": now_date_str,
        "channel_url": url,
        "source": "rumble.com",
        "url": url,
        "duration": "",
        "description": "",
        "img_src": imageUrl,
        "img_status": 200,
        "img_width": 640,
        "img_height": 360,
        // All this stuff is to enable auto play.
        "iframe_src": videoUrl,
        "views": "",
        "profile_img_src": "",
        "subtitles_url": "",
        "is_ad": true,
    };
    return out;
}


export function getAdvertisement() {
    // Returns a json representing a video.
    const url = "https://www.mymoviesplus.com/products/the-real-timeline?code=BEELADY";
    const imageUrl = "https://static-3.bitchute.com/live/cover_images/4E5499nDJCqG/ad0dUJlrGmSp_640x360.jpg";
    const vidId = "ad0dUJlrGmSp";
    const title = "George Floyd: The Real Timeline<br><br>A new documentary by Maryam Henein exposing the truth about the George Floyd psyop.";
    const type = "bitchute";
    if (type === "bitchute") {
        return makeBitchuteAdvert(url, imageUrl, vidId, title);
    }
    if (type === "rumble") {
        return makeRumbleVideo(url, imageUrl, vidId, title);
    }
    console.error("Unknown advertisement type: ", type);
    return undefined;
}

