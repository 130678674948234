// An in-memory/disk cache for json files.
import { timeMs, isUndefined } from './util';
import { gzipUncompressJson, gzipUncompressJsonAsync } from './gzip';
import { base64ToBytes, bytesToBase64 } from './base64';
import { CreateResourceCache } from './resource_cache';  // eslint-disable-line
import 'whatwg-fetch';  // Polyfills fetch used in fetchJson

// Holds all of the in-memory cache of fetched json objects.
// Each entry is a dictionary of the form:
// { timestamp: <INTEGER_MS>, data: <JSON_OBJ> }
const g_inmemory_cache = new Map();
const g_session_cache = await CreateResourceCache("session", 'json-cache');
const g_disk_cache = await CreateResourceCache("disk", "json-cache");
const g_all_caches = [
    g_inmemory_cache,
    g_session_cache,
    g_disk_cache,
];

const gFetchCallbackMap = new Map();

const CACHE_INMEMORY = 0;
const CACHE_SESSION = 1;
const CACHE_DISK = 2;

function fetchJson(theUrl, callback_success) {
    window.fetch(theUrl).then(response => {
        // If the URL ends in .gz then it's a gzip file and the
        // bytes should be handed down as is so it can possibly be
        // stored to disk. Otherwise send a fully parsed json object
        // to the callback.
        if (response.status !== 200) {
            console.log(`ERROR (json_cache_fetcher): ${theUrl} response.status is ${response.status}`);
        }
        if (theUrl.endsWith('.gz')) {
            // Pass the blob bytes down.
            response.blob().then(data => {
                data.arrayBuffer().then(binary => {
                    callback_success(binary);
                });
            }).catch((err) => {
                console.log("Error:", err);
            });
        } else {
            // Do decoding here.
            response.json().then((json_data) => {
                callback_success(json_data);
            }).catch((err) => {
                console.log("Error:", err);
            });
        }
    })
        .catch((err) => {
            console.log("Error:", err);
        });
}

async function fetchJsonAsync(theUrl) {
    const data = await window.fetch(theUrl);
    if (data.status !== 200) {
        throw new Error(`ERROR (json_cache_fetcher): ${theUrl} response.status is ${data.status}`);
    }
    if (theUrl.endsWith('.gz')) {
        const blob = await data.blob();
        const binary = await blob.arrayBuffer();
        return binary;
    }
    return await data.json();
}


function jsonCacheClear() {
    g_all_caches.forEach(cache => cache.clear());
}

function onFetchCallbackDispatch(url, new_data) {
    console.log('onFetchCallbackDispatch', url, new_data)
    // print out the stack trace
    console.log(new Error().stack);
    let json_data = null;
    let uint8_data = null;
    // Url's ending in .gz are going to be in binary format
    // and need to be inflated. Otherwise expect a full formed
    // json representation for new_data.
    if (url.endsWith('.gz')) {
        uint8_data = new Uint8Array(new_data);
        json_data = gzipUncompressJson(uint8_data);
    } else {
        json_data = new_data;
    }
    const obj = gFetchCallbackMap.get(url);
    console.log('object', url, 'is', obj);
    console.assert(obj);
    const { callbacks } = obj;
    obj.callback = [];
    obj.fetchCompleted = true;
    obj.data = new_data;
    if (obj.cache_type === CACHE_SESSION || obj.cache_type === CACHE_DISK) {
        // Create a disk object of either the json or the json gz data
        // and store it to disk.
        console.log('storing to disk', url, obj.cache_type, obj.fetchTime)
        const disk_obj = { "fetch_time": obj.fetchTime };
        if (url.endsWith('.gz')) {
            disk_obj.data = bytesToBase64(uint8_data);
        } else {
            disk_obj.data = json_data;
        }
        if (obj.cache_type === CACHE_SESSION) {
            // Store it to the session cache.
            console.log('storing to session cache', url)
            g_session_cache.set(url, disk_obj);
        } else if (obj.cache_type === CACHE_DISK) {
            // Store it to the disk cache.
            console.log('storing to disk cache', url)
            g_disk_cache.set(url, disk_obj);
        } else {
            console.assert(false, `Unknown cache type ${obj.cache_type}`);
        }
    }
    console.log('dispatching callbacks', url, callbacks.length)
    callbacks.forEach((cb) => { cb(json_data); });
}

async function jsonCacheFetch(json_url, time_before_refetch_ms, cache_type, on_completion) {
    console.log('jsonCacheFetch', json_url, time_before_refetch_ms, cache_type, on_completion)
    if (on_completion == null) {
        // Set the null function, which is easier to reason about then sending
        // a null through the call tree.
        on_completion = () => { };  // eslint-disable-line
    }
    let obj = gFetchCallbackMap.get(json_url);
    console.log('object', json_url, 'is', obj, 'with cache type', cache_type);
    // console.log('object', json_url, 'is', obj, 'with cache type', cache_type);
    // TODO: get rid of cache-type session.
    if (isUndefined(obj) && (cache_type === CACHE_SESSION || cache_type === CACHE_DISK)) {
        console.log("attempting to find object in disk cache.")
        let val = null;
        if (cache_type === CACHE_DISK) {
            console.log(`fetching object from disk cache: ${json_url}`)
            val = await g_disk_cache.get(json_url);
        } else {
            console.log(`fetching object from session cache: ${json_url}`)
            val = g_session_cache.get(json_url);
        }
        if (val && val.data.length > 0) {
            console.log("Found object in disk cache");
            const age = timeMs() - val.fetch_time;
            if (age < time_before_refetch_ms) {
                // Found it in the cache, so let's load it up to the memory cache.
                // console.log("Found object in session cache");
                obj = new Map();
                obj.fetchCompleted = true;
                obj.url = json_url;
                obj.data = val.data;
                obj.cache_type = cache_type;
                obj.callbacks = [];
                obj.fetchTime = val.fetch_time;
                let data = null;
                if (json_url.endsWith('.gz')) {
                    const tmp = base64ToBytes(obj.data);
                    data = gzipUncompressJson(tmp);
                } else {
                    data = obj.data;
                }
                on_completion(data);
                return;
            }
        } else {
            console.log("Could not find object in disk cache.");
        }
        // Get it from the session cache.
    }
    if (isUndefined(obj)) {  // Expired object or first time fetching it.
        //console.log("fetching object from the network.");
        console.log(`fetching object from the network: ${json_url}`)
        obj = new Map();
        obj.fetchCompleted = false;
        obj.url = json_url;
        obj.data = null;
        obj.cache_type = cache_type;
        obj.callbacks = [on_completion];
        obj.fetchTime = timeMs();
        gFetchCallbackMap.set(json_url, obj);
        const json_data = await fetchJsonAsync(json_url);
        onFetchCallbackDispatch(json_url, json_data);
        return;
    }
    // console.assert(cache_type === obj.cache_type, "Keys must maintain the same storage type.");
    if (!obj.fetchCompleted) {
        console.log(`fetching object from the network: ${json_url}`)
        obj.callbacks.push(on_completion);
        return;
    }
    const age_ms = timeMs() - obj.fetchTime;
    if (age_ms < time_before_refetch_ms) {
        let data = null;
        if (json_url.endsWith('.gz')) {
            data = gzipUncompressJson(new Uint8Array(obj.data));
        } else {
            data = obj.data;
        }
        on_completion(data);
    }
    // The object has expired.
    console.log('object has expired', json_url, time_before_refetch_ms, cache_type, on_completion)
    gFetchCallbackMap.delete(json_url);
    // At this point, the cache object has been expired/evicted so
    // the fetch operation is executed again. This next call will
    // take a different execution path to refetch the vacant
    // json that's requested.
    jsonCacheFetch(json_url, time_before_refetch_ms, cache_type, on_completion);
}

export {
    jsonCacheFetch,
    jsonCacheClear,
    fetchJsonAsync,
    CACHE_INMEMORY,  // Super transient.
    CACHE_SESSION,   // Stores to disk, unless it's iOS PWA mode (grgh!!)
    CACHE_DISK       // Works correctly on all platforms (it appears).
};