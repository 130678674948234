// A slide in popup to remind the user to book mark the site.
import { parseMoment, isIPhone } from "./util";
import { domPopupBookmark, domPopupContent } from "./dom";
import { db_instance } from "./db";

// Three days.
const POPUP_COOLDOWN_MS = 1000 * 60 * 60 * 24 * 3;
const ADDRESS_BAR_IS_TOP = !isIPhone();

/*
function run_demo() {
    let toggle = false;
    let interval;
    function toggle_test() {
        clearInterval(interval);
        popupBookmarkSet(toggle);
        toggle = !toggle;
        interval = setInterval(toggle_test, toggle ? 1000 : 4000);
    }
    setTimeout(toggle_test, 500);
}
*/


export function popupBookmarkSet(on) {
    const popup_bookmark = domPopupBookmark();
    if (on) {
        if (popup_bookmark.classList.contains('active')) {
            return;
        }
        popup_bookmark.classList.add('active');
    } else {
        popup_bookmark.classList.remove('active');
    }
}

export function popupBookmarkGet() {
    const popup_bookmark = domPopupBookmark();
    return popup_bookmark.classList.contains('active');
}


function run_popup() {
    setTimeout(() => {
        popupBookmarkSet(true);
        setTimeout(() => {
            popupBookmarkSet(false);
        }, 5000);
    }, 3000);
}


export async function popupBookMarkInit() {
    const db = db_instance();
    let popup = false;
    const last_run = await db.getItem("popup_bookmark_last_run");
    if (last_run === null) {
        popup = true;
        db.setItem("popup_bookmark_last_run", Date.now());
    } else {
        const d = parseMoment(last_run);
        const now = parseMoment(Date.now());
        const diff = now - d;
        if (diff > POPUP_COOLDOWN_MS) {
            // Every three days, run the popup.
            popup = true;
            db.setItem("popup_bookmark_last_run", now);
        }
    }

    const dom_popup_bookmark = domPopupBookmark();
    setTimeout(() => { dom_popup_bookmark.style.visibility = "visible"; }, 1000);
    let content = "👆bookmark me";
    if (!ADDRESS_BAR_IS_TOP) {
        content = "👇bookmark me";
        dom_popup_bookmark.classList.add('bottom');
    }
    const dom_popup_content = domPopupContent();
    dom_popup_content.innerText = content;
    if (popup) {
        run_popup();
    }
}