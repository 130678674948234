import { db_instance } from "./db";

const SESSION_VERSION = '2';

class ResourceCache {
    toKey(url) {
        return `${this.prefix}-${url}`;
    }

    async init() {
        this.initialized = true;
        const index = await this.db.getItem(this.index_key);
        this.version = await this.db.getItem(this.session_version_key);
        if (index === null) {
            this.index = [];
            this.db.setItem(this.index_key, JSON.stringify(this.index));
            this.db.setItem(this.session_version_key, SESSION_VERSION);
            return;
        }
        this.index = JSON.parse(index);
        if (this.version !== SESSION_VERSION) {
            console.log(`Clearing session cache because version masmatch: ${this.version} !== ${SESSION_VERSION}`);
            this.clear();
            this.version = SESSION_VERSION;
            this.db.setItem(this.session_version_key, SESSION_VERSION);
        }
    }

    constructor(store_mode, prefix) {
        this.initialized = false;
        this.store_mode = store_mode;
        this.prefix = prefix;
        this.index_key = this.toKey('---index');
        this.session_version_key = this.toKey(`---version`);
        this.initialized = false;
        if (store_mode === 'session') {
            this.db = sessionStorage;
        } else if (store_mode === 'local') {
            this.db = localStorage;
        } else if (store_mode === "disk") {
            this.db = db_instance();
        } else {
            console.assert(false);
        }
    }

    async get(url) { /* eslint-disable-line */
        const key = this.toKey(url);
        const out_str = await this.db.getItem(key);
        if (typeof out_str === 'undefined') {
            return null;
        }
        return JSON.parse(out_str);
    }

    set(url, json_obj) {
        const key = this.toKey(url);
        const json_str = JSON.stringify(json_obj);
        if (!this.index.includes(key)) {
            this.index.push(key);
        }
        const index_str = JSON.stringify(this.index);
        try {
            this.db.setItem(key, json_str);
        } catch (err) {
            console.error(err);
        }
        this.db.setItem(this.index_key, index_str);
    }

    has(url) { /* eslint-disable-line */
        const key = this.toKey(url);
        const out = this.db.getItem(key);
        if (out === null || typeof out === 'undefined') {
            return false;
        }
        return true;
    }

    delete(url) {
        const key = this.toKey(url);
        this.index.delete(key);
        this.db.removeItem(key);
    }

    clear() {
        this.index.forEach((key) => {
            this.db.removeItem(key);
        });
        this.db.removeItem(this.index_key);
        this.index = [];
    }
}


export async function CreateResourceCache(store_mode, prefix) {
    const out = new ResourceCache(store_mode, prefix);
    await out.init();
    return out;
}