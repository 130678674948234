
import { sidebar_nav_init, sidebar_nav_show, sidebar_nav_hide, sidebar_nav_is_visible } from "./third_party/sidebar_nav";
import { jsonCacheFetch, CACHE_DISK } from './json_cache_fetcher';

const CHANNEL_MAP_DATA = './assets/json/nav/index.json';  // Contains link information.
const CHANNEL_MAP_DATA_DURATION = 1000 * 60 * 60;  // Before refetch.
const SHOW_FLOATING_NAV = false;

function initSideBar() {
    const work = (channel_index_json) => {
        let html = '';
        html += `<h2>Creators</h2>`;
        html += `<ul class="links">`;
        channel_index_json.forEach((tup) => {
            const li = `<li><a href="#${tup[1]}">${tup[0]}</a></li>`;
            html += li;
        });
        html += '</ul>';
        html += `<h2>Talk with us</h2>`;
        html += `<ul class="links">`;
        html += `  <li><a href="https://reddit.com/r/blast_video" target="_blank">reddit.com/r/blast_video</a></li>`;
        html += `  <li><a href="https://www.guilded.gg/BlastVideo" target="_blank">guilded.gg/BlastVideo</a></li>`;
        html += '</ul>';
        html += `<h2>Data Feeds</h2>`;
        html += `<ul class="links">`;
        html += `  <li><a href="assets/inclusion_list.py" target="_blank">Inclusion List</a></li>`;
        html += `  <li><a href="assets/gen_trending_json.py" target="_blank">Trending Algorithm</a></li>`;
        html += `  <li><a href="assets/json/independent.json" target="_blank">Json Feed - Independent</a></li>`;
        html += `  <li><a href="assets/json/mainstream.json" target="_blank">Json Feed - Mainstream</a></li>`;
        html += `  <li><a href="assets/json/populist.json" target="_blank">Json Feed - Populist</a></li>`;
        html += `  <li><a href="assets/json/health.json" target="_blank">Json Feed - Health</a></li>`;
        html += `  <li><a href="prayer.txt" target="_blank">Prayer</a></li>`;
        html += '</ul>';
        // Defer actual navigation initialization.
        setTimeout(() => { sidebar_nav_init("", html, SHOW_FLOATING_NAV); });
    };
    const defer = () => {
        jsonCacheFetch(CHANNEL_MAP_DATA, CHANNEL_MAP_DATA_DURATION, CACHE_DISK, work);
    };
    // Make this super late processing.
    setTimeout(defer, 1000);
}


function sideBarShow(val) {
    if (val) {
        sidebar_nav_show();
    } else {
        sidebar_nav_hide();
    }
}

function sideBarVisible() {
    sidebar_nav_is_visible();
}

export { initSideBar, sideBarShow, sideBarVisible };
